exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chitchats-help-js": () => import("./../../../src/pages/chitchats/help.js" /* webpackChunkName: "component---src-pages-chitchats-help-js" */),
  "component---src-pages-chitchats-js": () => import("./../../../src/pages/chitchats.js" /* webpackChunkName: "component---src-pages-chitchats-js" */),
  "component---src-pages-chitchats-privacy-js": () => import("./../../../src/pages/chitchats/privacy.js" /* webpackChunkName: "component---src-pages-chitchats-privacy-js" */),
  "component---src-pages-fasttravels-help-js": () => import("./../../../src/pages/fasttravels/help.js" /* webpackChunkName: "component---src-pages-fasttravels-help-js" */),
  "component---src-pages-fasttravels-js": () => import("./../../../src/pages/fasttravels.js" /* webpackChunkName: "component---src-pages-fasttravels-js" */),
  "component---src-pages-fasttravels-privacy-js": () => import("./../../../src/pages/fasttravels/privacy.js" /* webpackChunkName: "component---src-pages-fasttravels-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-syntest-help-js": () => import("./../../../src/pages/syntest/help.js" /* webpackChunkName: "component---src-pages-syntest-help-js" */),
  "component---src-pages-syntest-js": () => import("./../../../src/pages/syntest.js" /* webpackChunkName: "component---src-pages-syntest-js" */),
  "component---src-pages-syntest-privacy-js": () => import("./../../../src/pages/syntest/privacy.js" /* webpackChunkName: "component---src-pages-syntest-privacy-js" */)
}

